import React from "react";
import classes from './Station.module.scss';

const Stations = ({ stations: { type, items } }) => {
    if (type === 'ALL') {
        return <p>Все станции</p>;
    } else {
        return (
            <ul className={classes.Ul}>
                { items.map((item, index) => <li key={index}>{item.label} ({item.code})</li>) }
            </ul>
        )
    }
}


export default Stations;