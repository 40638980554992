import React from "react";
import Select from "react-select";
import classes from './Pay.module.scss';
import { useMutation } from "@apollo/client";
import { UPDATE_PAID_SUBSCRIBE } from "../../graphql/graphql";

const PaidStatus = ({ pay: { method, paid, status }, id }) => {
    const [updatePaidStatus] = useMutation(UPDATE_PAID_SUBSCRIBE);

    console.log(paid, method);

    const options = [
        { value: 'paid', label: 'Оплачен' },
        { value: 'sended', label: 'Счет выставлен' },
        { value: 'waiting', label: 'Выставить счет' }
    ]

    if (method === 'INVOICE' && !paid) {
        return (
            <Select
                options={options}
                defaultValue={options.filter(option => option.value === status)}
                onChange={(event) => event.value && updatePaidStatus({
                    variables: {
                        id,
                        status: event.value
                    }
                })}
            />
        )
    }

    if (method === 'CARD' && !paid) {
        return <span className={[classes.Item, classes.Waiting].join(' ')}>Ожидаем оплату<br />({method})</span>;
    }


    if (paid || method === 'PROMO') {
        return <span className={[classes.Item, classes.Paid].join(' ')}>Оплачено<br />({method})</span>;
    }

    return (
        <>Не требует оплаты</>
    )
};



export default PaidStatus;
