import React from 'react';
import classes from './Checkbox.module.scss';
import checkbox from '../../../assets/img/check.svg'

const Checkbox = ({ name, checked = false, label, value, onChange, error, align }) => {
    const cls = [classes.Radio];
    if (error) { cls.push(classes.Error) }
    if (align) { cls.push(classes.Centered) }

    return (
        <label className={cls.join(' ')}>
             <span className={classes.Wrapper}>
                  <input className={classes.Input} type="checkbox" name={name} value={value} onChange={(event) => onChange(event)} checked={checked}/>
                  <span className={classes.Control}>
                       <img src={checkbox} alt=""/>
                  </span>
             </span>
            <span className={classes.Label}>{label}</span>
            <span className={classes.Message}>{error}</span>
        </label>
    )
}

export default Checkbox;