import React from 'react';
import Page from '../../components/Page/Page';
import classes from './Home.module.scss';
import Informer from "../../components/Informer/Informer";
import {useQuery} from "@apollo/client";
import {FETCH_SUBSCRIBE_COUNT} from "../../graphql/graphql";
import Loader from "react-loader-spinner";

const Home = () => {
    const {loading, data: {getBsSubscriptionsCounter: count} = {}} = useQuery(FETCH_SUBSCRIBE_COUNT, {
        pollInterval: 2000
    });

    if (loading || !count) return <Loader
        className="loading"
        type="Circles"
        color="#284255"
        height={50}
        width={50}
        timeout={3000}
    />;

    return (
        <Page title="Информационная панель">
            <div className={classes.Wrapper}>
                <Informer
                    to="/paid"
                    type="password"
                    title="Ожидают выдачу пароля"
                    count={count.waitingPassword}
                />
                <Informer
                    to="/invoice"
                    type="invoice"
                    title="Ожидают счет на оплату"
                    count={count.waitingInvoice}
                />

                <Informer
                    to="/subscribes"
                    type="active"
                    title="Активные подписки"
                    count={count.activeSubscribe}
                />

                <Informer
                    to="/coming"
                    type="coming"
                    title="Заканчивающиеся подписки"
                    count={count.comingSubscribe}
                />

                <Informer
                    to="/end"
                    type="end"
                    title="Подписка закончилась"
                    count={count.endSubscribe}
                />

                <Informer
                    to="/archive"
                    type="archive"
                    title="Архивные подписки"
                    count={count.archiveSubscribe}
                />
            </div>
        </Page>
    )
}

export default Home;


