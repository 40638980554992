import React, { useState } from 'react';
import classes from './ModalRegister.module.scss';
import ModalWrapper from "../ModalWrapper";
import Group from "../../UI/Group/Group";
import Button from "../../UI/Button/Button";
import Checkbox from '../../UI/Checkbox/Checkbox'
import { Input } from "../../UI/Input/Input";
import { useForm } from "../../../hoc/form";
import { useMutation } from "@apollo/client";
import { useModal } from "../ModalContext";
import { REGISTER_USER } from "../../../graphql/graphql";

const ModalRegister = () => {
    const { hide } = useModal();
    const [errors, setErrors] = useState({});
    const {onChange, onSubmit, values} = useForm(registerCallback, {
        name: '',
        lastname: '',
        company: '',
        city: '',
        phone: '',
        email: '',
        sendPassword: false
    });

    const [createUser] = useMutation(REGISTER_USER, {
        update(_, {data}) {
            hide();
        },
        onError(err) {
            setErrors(err.graphQLErrors[0].extensions.exception.errors);
            console.log(err.graphQLErrors[0].extensions.exception.errors)
        },
    });

    function registerCallback() {
        createUser({
            variables: values
        });
    }

    return (
        <ModalWrapper
            title="Добавить пользователя"
            desc="Для добавления пользователя введите данные:"
        >
            <form className={classes.Form} onSubmit={onSubmit} autoComplete="off">
                <Group label="Имя" error={errors.name}>
                    <Input
                        name="name"
                        value={values.name}
                        placeholder="Введите имя пользователя"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Фамилия" error={errors.lastname}>
                    <Input
                        name="lastname"
                        value={values.lastname}
                        placeholder="Введите фамилию пользователя"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Компания" error={errors.company}>
                    <Input
                        name="company"
                        value={values.company}
                        placeholder="Введите наименование компании"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Город" error={errors.city}>
                    <Input
                        name="city"
                        value={values.city}
                        placeholder="Введите город пользователя"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Номер телефона" error={errors.phone}>
                    <Input
                        name="phone"
                        type="tel"
                        value={values.phone}
                        placeholder="Введите номер пользователя"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Email" error={errors.email}>
                    <Input
                        name="email"
                        type="test"
                        value={values.email}
                        placeholder="Введите e-mail"
                        onChange={onChange}
                    />
                </Group>

                <Group>
                    <Checkbox
                        name="sendPassword"
                        label="Выслать пароль пользователю?"
                        onChange={onChange}
                        checked={values.sendPassword}
                    />
                </Group>
                <Group>
                    <Button size="normal">Добавить пользователя</Button>
                </Group>
            </form>
        </ModalWrapper>
    )
}


export default ModalRegister;