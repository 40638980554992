import React from 'react';
import classes from './Modal.module.scss';
import ModalPassword from "./ModalPassword/ModalPassword";
import ModalDelete from "./ModalDelete/ModalDelete";
import ModalInstruction from "./ModalInstruction/ModalInstruction";
import ModalRegister from "./ModalRegister/ModalRegister";
import ModalStation from "./ModalStation/ModalStation";
import ModalMigrate from "./ModalMigrate/ModalMigrate";
import ModalSubscribeCreate from "./ModalSubscribe/ModalSubscribeCreate";
import { useModal } from "./ModalContext";
import ModalProlong from "./ModalProlong/ModalProlong";
// import { SubscribeProvider } from "./ModalSubscribe/ModalSubscribeContext";
// import { MigrateProvider } from "./ModalMigrate/ModalMigrateContext";

const Modal = () => {
    const modal = useModal();

    if (!modal.visible) {
        document.body.style.overflow = "";
        return null;
    } else  {
        document.body.style.overflow = "hidden";
    }

    return (
        <div className={classes.Container}>
            <div className={classes.Wrapper}>
                {modal.modalType === 'passwordSendler' && <ModalPassword /> }
                {modal.modalType === 'deleteConfirm' && <ModalDelete /> }
                {modal.modalType === 'createInstruction' && <ModalInstruction /> }
                {modal.modalType === 'createUser' && <ModalRegister /> }
                {modal.modalType === 'createStation' && <ModalStation /> }
                {modal.modalType === 'migrateConfirm' && <ModalMigrate /> }
                {modal.modalType === 'prolongSubscribe' && <ModalProlong /> }
                {modal.modalType === 'createSubscribe' && <ModalSubscribeCreate /> }
            </div>
        </div>
    )
}

export default Modal;