import React, { useState } from 'react';
import classes from './User.module.scss';
import Page from '../../components/Page/Page';
import ButtonIcon from "../../components/UI/ButtonIcon/ButtonIcon";
import Subscribes from "../../components/Subscribes/Subscribes";
import Button from "../../components/UI/Button/Button";
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../graphql/graphql";
import { useModal } from "../../components/Modal/ModalContext";
import Loader from "react-loader-spinner";


const User = () => {
    let { id } = useParams();
    const { show } = useModal();
    const {loading, data: { getUserById: user } = {}} = useQuery(GET_USER, {
        variables: {
            id
        },
        pollInterval: 1000
    });

    if (loading || !user) return <Loader
        className="loading"
        type="Circles"
        color="#284255"
        height={50}
        width={50}
        timeout={3000}
    />;

    return (
        <Page title={`Пользователь: ${user.login}`}>
            <div className={classes.Info}>
                <div className={classes.User}>
                    <div>
                        <span>ID: {user.login}</span>
                        <p>{user.lastname} {user.name}</p>
                    </div>
                    <div>
                        <span>Номер телефона:</span>
                        <p>{user.phone}</p>
                    </div>
                    <div>
                        <span>Email:</span>
                        <p>{user.email}</p>
                    </div>
                    <div>
                        <span>Компания:</span>
                        <p>{user.company}</p>
                    </div>
                    <div>
                        <span>Город:</span>
                        <p>{user.city}</p>
                    </div>
                </div>
                <div className={classes.Controls}>
                    <ButtonIcon type="edit"/>
                    <ButtonIcon type="block"/>
                    <ButtonIcon type="delete"/>
                </div>
            </div>
            <div className={classes.Buttons}>
                <Button
                    size="normal"
                    onClick={() => show('createSubscribe')}>
                    Добавить подписку
                </Button>
            </div>
            <Subscribes subscriptions={user.subscriptions}/>
        </Page>
    )
};



export default User;