import React from 'react';
import Select from "react-select";
import Group from "../Group/Group";
import classes from "../Input/Input.module.scss";
import { useModal } from "../../Modal/ModalContext";


const Status = ({ error, disabled, name, placeholder }) => {
    const { setStatus } = useModal();

    const status = [
        {
            label: 'Платный',
            value: 'paid'
        },
        {
            label: 'Бесплатный',
            value: 'free'
        },
        {
            label: 'Гарантийный',
            value: 'garante'
        }
    ];


    return (
        <Group label="Статус приемника" error={null}>
            { status &&
                <Select
                    isDisabled={disabled && true}
                    className={error && classes.ErrorSelect}
                    classNamePrefix="select"
                    placeholder={placeholder}
                    options={status && status.map(item => {
                        return {
                            ...item,
                            name: name,
                            value: item.value,
                            label: item.label
                        }
                    })}
                    onChange={(event) => setStatus(event)}
                />
            }
        </Group>
    )
};

export default Status;


