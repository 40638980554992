import React, { useState } from 'react';
import Group from "../Group/Group";
import DatePicker from "react-datepicker";
import { useModal } from "../../Modal/ModalContext";
import { setDefaultLocale, registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';

setDefaultLocale('ru', ru);
registerLocale('ru', ru);


const DatePick = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const { setDate } = useModal();

    return (
        <>
            <Group label="Дата старта подписки">
                <DatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={startDate}
                    onChange={date => {
                        let value = {
                            name: 'activatedAt',
                            value: new Date(date).toISOString()
                        }
                        setStartDate(date);
                        setDate(value);
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    locale="ru"
                />
            </Group>

            <Group label="Дата окончания подписки">
                <DatePicker
                    dateFormat="dd.MM.yyyy"
                    selected={endDate}
                    onChange={date => {
                        let value = {
                            name: 'disabledAt',
                            value: new Date(date).toISOString()
                        }
                        setEndDate(date);
                        setDate(value);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    locale="ru"
                />
            </Group>
        </>
    )
};

export default DatePick;