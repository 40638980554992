import React, {useState} from 'react';
import Page from '../../components/Page/Page';
import Subscribes from "../../components/Subscribes/Subscribes";
import {useQuery} from "@apollo/client";
import {GET_ACTIVE_SUBSCRIPTIONS} from "../../graphql/graphql";
import {useSearch} from "../../context/search";
import Button from "../../components/UI/Button/Button";
import Search from "../../components/Search/Search";
import {useModal} from "../../components/Modal/ModalContext";


const PageActiveSubscribes = () => {
    const {show} = useModal();
    const {searchValue} = useSearch();
    const [page, setPage] = useState(1);

    const {loading, data: {getBsSubscriptions: response} = {}, fetchMore} = useQuery(GET_ACTIVE_SUBSCRIPTIONS, {
        pollInterval: 1000,
        variables: {
            page: page,
            limit: 10,
            search: searchValue
        }
    });


    return (
        <Page
            title="Активные подписки"
            callback={setPage}
            fetchMore={fetchMore}
            loading={loading}
            paginator={!loading && response.paginator}
            controls={
                <>
                    <Button
                        size="normal"
                        onClick={() => show('createSubscribe')}>
                        Добавить подписку
                    </Button>
                    <Search
                        setPage={setPage}
                        placeholder="Поиск: ID-подписки, серийны номер"
                    />
                </>
            }>

            {response && <Subscribes subscriptions={response.subscriptions}/>}
        </Page>
    )
}


export default PageActiveSubscribes;