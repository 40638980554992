import React from 'react';
import classes from './ModalDelete.module.scss';
import ModalWrapper from "../ModalWrapper";
import Button from "../../UI/Button/Button";
import { useModal } from "../ModalContext";


const ModalDelete = () => {
    const { modalValues, hide } = useModal();

    return (
        <ModalWrapper
            title={modalValues.title}
            close={false}
        >
            <div className={classes.Buttons}>
                <Button
                    size="normal"
                    onClick={modalValues.deleteHandler}
                >
                    Да
                </Button>
                <Button
                    size="normal"
                    onClick={() => hide()}
                >
                    Нет
                </Button>
            </div>
        </ModalWrapper>
    )
}


export default ModalDelete;