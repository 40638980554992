import { withRouter } from "react-router-dom";
import jwtDecode from "jwt-decode";

const AuthVerify = ({ history, component: Component, ...rest }) => {
    history.listen(() => {  // <--- Here you subscribe to the route change
        const token = localStorage.getItem('jwtToken');

        if (token) {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp * 1000 < Date.now()) {
                localStorage.removeItem('jwtToken');
                history.push('/');
            }
        }
    });

    return <div></div>

};

export default withRouter(AuthVerify);