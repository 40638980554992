import React from 'react';
import Page from "../../components/Page/Page";
import Controls from "../../components/Controls/Controls";
import Button from "../../components/UI/Button/Button";
import { Table, Td, Tr } from "../../components/UI/Table/Table";
import { useModal } from "../../components/Modal/ModalContext";
import { useQuery } from "@apollo/client";
import { FETCH_INSTRUCTIONS } from "../../graphql/graphql";

const Instructions = () => {
    const { show } = useModal();

    const {loading, data: { getBsInstructions: instructions } = {}} = useQuery(FETCH_INSTRUCTIONS, {
        pollInterval: 2000
    });


    return (
        <Page
            title="Инструкции"
            loading={loading}
            controls={
                <Button onClick={() => show('createInstruction')} size="normal">Добавить инструкцию</Button>
            }
        >
            <Table value={[
                {name: "id", label: '№'},
                {name: "name", label: 'Название инструкции'},
                {name: "name", label: 'Файл'},
                {name: "controls", label: ''}
            ]}>
                {instructions && instructions.map((item, index) => {
                    return (
                        <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{item.label}</Td>
                            <Td>{item.file}</Td>
                            <Td type="controls">
                                <Controls
                                    id={item.id}
                                    file={item.file}
                                    controls={{
                                        infoInstruction: true,
                                        deleteInstruction: true
                                    }}
                                />
                            </Td>
                        </Tr>
                    )
                })}
            </Table>
        </Page>
    )
};

export default Instructions;

