import React from 'react';
import classes from "./Informer.module.scss";
import classnames from 'classnames';
import arrow from '../../assets/img/right-arrow.svg';
import { useHistory } from "react-router-dom";

const Informer = ({ to, title, count, type }) => {
    let history = useHistory();

    let clsColor = classnames({
        [classes.Password]: type === 'password',
        [classes.Invoice]: type === 'invoice',
        [classes.Active]: type === 'active',
        [classes.Coming]: type === 'coming',
        [classes.End]: type === 'end',
        [classes.Archive]: type === 'archive'
    });


    return (
        <div className={classnames(classes.Item, classes.Item30)} onClick={() => history.push(to)}>
            <h3 className={clsColor}>
                {title}:
            </h3>
            <p className={clsColor}>{count}</p>
            <img src={arrow} alt=""/>
        </div>
    )
}

export default Informer;