import React, {useContext, useReducer} from 'react';

export const ModalContext = React.createContext();

export const useModal = () => {
    return useContext(ModalContext);
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'show': return {
            ...state,
            visible: true,
            modalType: action.modalType,
            modalValues: { ...action.modalValues }
        }

        case 'hide': return {
            ...state,
            visible: false,
            modalType: null
        }

        case 'values': return  {
            ...state,
            values: action.values
        }
        case 'brand': return {
            ...state,
            brandType: action.brand
        }
        case 'plan': return {
            ...state,
            planCount: action.count
        }
        case 'errors': return  {
            ...state,
            errors: action.errors
        }

        default:
            return state;
    }
}


export const ModalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
        visible: false,
        modalType: null,
        modalValues: { },
        planCount: 0,
        brandType: '',
        values: {
            device: {
                brand: '',
                model: '',
                serial: ''
            },
            station: {
                type: 'NONE',
                items: []
            },
            pay: {
                method: 'MANAGER'
            },
            plan: {
                id: '',
                type: ''
            },
            confirm: {
                provisions: true,
                auth: true
            },
            invoice: {
                bin: '',
                inv: '',
                bank: ''
            },
            file: { },
            userID: {
                id: ''
            },
            status: {
                label: '',
                value: ''
            },
            date: {
                activatedAt: '',
                disabledAt: ''
            }
        },
        errors: { }
    })

    const show = (modalType, modalValues) => dispatch({ type: 'show', modalType, modalValues });
    const hide = () => dispatch({ type: 'hide' });

    const setValues = (values, name) => {
        dispatch({type: 'values', values});
        clearError(name);
    };

    const setErrors = (errors) => dispatch({ type: 'errors', errors });

    const setBrand = (brand) => dispatch({ type: 'brand', brand });

    const setPlan = (count) => dispatch({ type: 'plan', count });



    const inputHandler = (event) => {
        const { name, value, type, checked} = event;

        const inputGroup = name.split('.')[0];
        const inputName = name.split('.')[1];

        console.log(inputGroup, inputName, value)

        setValues({
            ...state.values,
            [inputGroup]: {
                ...state.values[inputGroup],
                [inputName]: type === 'checkbox' ? checked : value
            }
        }, inputName);
    };

    const fileHandler = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setValues({
            ...state.values,
            file: file
        }, 'file');
    }

    const setDate = ({ name, value }) => {
        setValues({
            ...state.values,
            date: {
                ...state.values.date,
                [name]: value
            }
        }, 'date');
    }

    const setStatus = ({ label, value }) => {
        setValues({
            ...state.values,
            status: {
                ...state.values.status,
                label: label,
                value: value
            }
        }, 'status');
    }

    const stationHandler = (event) => {
        const inputName = event.name.split('.')[0];

        const stations = [ ...state.values.station.items ];
        stations[event.id] = { ...event.data };

        setValues({
            ...state.values,
            station: {
                ...state.values.station,
                items: [
                    ...stations
                ]
            }
        }, inputName);
    };

    const planHandler = (event) => {
        setValues({
            ...state.values,
            [event.name]: {
                id: event.id,
                type: event.type
            },
            station: {
                ...state.values.station,
                type: event.enumCount
            },
            pay: {
                ...state.pay,
                method: event.type === 'demo' ? 'DEMO' : 'MANAGER'
            }
        }, event.name);
    };

    const clearError = name => setErrors({ ...state.errors, [name]: '' });

    return (
        <ModalContext.Provider value={{
            visible: state.visible,
            modalType: state.modalType,
            modalValues: state.modalValues,
            planCount: state.planCount,
            values: state.values,
            brandType: state.brandType,
            errors: state.errors,
            order: state.order,
            setBrand,
            setDate,
            setPlan,
            setErrors,
            setStatus,
            inputHandler,
            fileHandler,
            planHandler,
            stationHandler,
            show,
            hide
        }}>
            { children }
        </ModalContext.Provider>
    )
}