import App from './App';
import React from 'react';
import { setContext } from "apollo-link-context";
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';


const httpLink = createUploadLink({
    uri: process.env.NODE_ENV === 'production' ? 'https://api.geokurs.kz/api' : 'http://localhost:5000/api'
});


const authLink = setContext(async () => {
    const token = localStorage.getItem('jwtToken');

    return {
        headers: {
            Authorization: token ? `Bearer ${token}` : ''
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-and-network",
        }
    }
});

export default (
    <ApolloProvider client={client}>
        <App/>
    </ApolloProvider>
)
