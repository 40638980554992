import React from "react";
import Stations from "../Stations/Stations";
import Controls from "../Controls/Controls";
import PaidStatus from "../Pay/Pay";
import zip from '../../assets/img/zip.svg';
import ModalImage from "react-modal-image";
import { Table, Td, Tr } from "../UI/Table/Table";
import { DateEnd, DateStart } from "../Date/Date";

const Subscribes = ({ subscriptions }) => {
    const path = process.env.NODE_ENV === 'production' ? 'https://api.geokurs.kz' : 'http://localhost:5000';

    return (
        <Table value={[
            {name: "image", label: 'Фото'},
            {name: "id", label: 'ID-прибора'},
            {name: "name", label: 'Наименование'},
            {name: "tarif", label: 'Тарифный план'},
            {name: "station", label: 'Базовые станции'},
            {name: "date", label: 'Дата активации'},
            {name: "date", label: 'Дата окончания'},
            {name: "status", label: 'Статус оплаты'},
            {name: "actions", label: 'Действия'}
        ]}>
            { subscriptions && subscriptions.map((item, index) => {
                return (
                    <Tr key={index} method={item.pay.method}>
                        <Td type="image">
                            { item.file.split('.')[1] === 'zip' || item.file.split('.')[1] === 'rar' ?
                                <a href={`${path}/uploads/bs_devices/${item.file}`} target="_blank" rel="noreferrer"><img src={zip} alt="" /></a>
                                :
                                <ModalImage
                                    small={`${path}/uploads/bs_devices/${item.file}`}
                                    large={`${path}/uploads/bs_devices/${item.file}`}
                                />

                            }
                        </Td>
                        <Td>{item.device.id}</Td>
                        <Td>
                            {item.device.brand} {item.device.model}<br />
                            № {item.device.serial}
                        </Td>
                        <Td>
                            {item.plan.label}
                            <br />
                            {item.plan.amount} тг.
                        </Td>
                        <Td>
                            <Stations
                                stations={item.station}
                            />
                        </Td>
                        <Td>
                            <DateStart
                                pay={item.pay}
                                date={item.date}
                            />
                        </Td>
                        <Td>
                            <DateEnd
                                pay={item.pay}
                                date={item.date}
                            />
                        </Td>
                        <Td>
                            <PaidStatus
                                id={item.id}
                                pay={item.pay}
                            />
                        </Td>
                        <Td type="controls">
                            { item.archive }
                            <Controls
                                id={item.id}
                                deviceId={item.device.id}
                                paidStatus={item.pay.paid}
                                available={item.available.status}
                                controls={{
                                    //editSubscribe: true,
                                    prolongSubscribe: true,
                                    deleteSubscribe: true,
                                    migrateSubscribe: true,
                                    passwordSubscribe: true,
                                    infoSubscribe: true,
                                    archive: !item.archive
                                }}
                            />
                        </Td>
                    </Tr>
                )
            })}
        </Table>
    )
};


export default Subscribes;