import React from 'react';
import classes from './Group.module.scss';


const Group = ({ children, label, error }) => {
    const cls = [classes.Wrapper];
    error && cls.push(classes.Error);
    return (
        <div className={cls.join(' ')}>
            <label htmlFor="" className={classes.Label}>{label}</label>
            { children }
            { error && <span>{error}</span> }
        </div>
    )
}

export default Group;