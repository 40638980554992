import React, { useState } from 'react';
import classes from './ModalStation.module.scss';
import ModalWrapper from "../ModalWrapper";
import Group from "../../UI/Group/Group";
import Button from "../../UI/Button/Button";
import { Input } from "../../UI/Input/Input";
import { useForm } from "../../../hoc/form";
import { useMutation } from "@apollo/client";
import { useModal } from "../ModalContext";
import { CREATE_STATION } from "../../../graphql/graphql";

const ModalStation = () => {
    const { hide } = useModal();
    const [errors, setErrors] = useState({});
    const {onChange, onSubmit, values} = useForm(registerCallback, {
        code: '',
        city: '',
        satellite: '',
        lat: '',
        lon: '',
        alt: '',
        n: '',
        e: ''
    });

    const [createStation] = useMutation(CREATE_STATION, {
        update(_, {data}) {
            hide();
        },
        onError(err) {
            setErrors(err.graphQLErrors[0].extensions.exception.errors);
            console.log(err.graphQLErrors[0].extensions.exception.errors)
        },
    });

    function registerCallback() {
        createStation({
            variables: {
                code: values.code,
                city: values.city,
                satellite: values.satellite,
                position: {
                    lat: values.lat,
                    lon: values.lon,
                    alt: values.alt
                },
                cords: {
                    n: values.n,
                    e: values.e
                }
            }
        });
    }

    return (
        <ModalWrapper
            title="Добавить станцию"
            desc="Для добавления станции введите данные:"
        >
            <form className={classes.Form} onSubmit={onSubmit} autoComplete="off">
                <Group label="Код станции" error={errors.code}>
                    <Input
                        name="code"
                        value={values.code}
                        placeholder="Введите код станции"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Город" error={errors.city}>
                    <Input
                        name="city"
                        value={values.city}
                        placeholder="Введите город"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Спутниковые группировки" error={errors.satelite}>
                    <Input
                        name="satellite"
                        value={values.satelite}
                        placeholder="Введите спутниковые группировки"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Расположение (lat)" error={errors.position}>
                    <Input
                        name="lat"
                        value={values.lat}
                        placeholder="Введите lat"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Расположение (lon)" error={errors.position}>
                    <Input
                        name="lon"
                        value={values.lon}
                        placeholder="Введите lon"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Расположение (alt)" error={errors.position}>
                    <Input
                        name="alt"
                        value={values.alt}
                        placeholder="Введите alt"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Координаты (n)" error={errors.cords}>
                    <Input
                        name="n"
                        value={values.n}
                        placeholder="Координаты n"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Координаты (e)" error={errors.cords}>
                    <Input
                        name="e"
                        value={values.e}
                        placeholder="Координаты e"
                        onChange={onChange}
                    />
                </Group>

                <Group>
                    <Button size="normal">Добавить станцию</Button>
                </Group>
            </form>
        </ModalWrapper>
    )
}


export default ModalStation;