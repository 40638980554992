import React, { useState, useContext } from 'react';
import Group from "../../components/UI/Group/Group";
import classes from './Login.module.scss';
import Button from "../../components/UI/Button/Button";
import { Input } from "../../components/UI/Input/Input";
import { AuthContext } from '../../context/auth';
import { useForm } from "../../hoc/form";
import { useMutation } from "@apollo/client";
import { LOGIN_USER } from "../../graphql/graphql";

const Login = (props) => {
    const context = useContext(AuthContext);
    const [errors, setErrors] = useState({});

    const { onChange, onSubmit, values } = useForm(loginUserHandler, {
        login: '',
        password: ''
    });

    const [loginUser] = useMutation(LOGIN_USER, {
        update(_, {data: { login: userDate }}) {
            context.login(userDate);
            props.history.push('/home')
        },
        onError(err) {
            setErrors(err.graphQLErrors[0].extensions.exception.errors);
        },
        variables: values
    })

    function loginUserHandler() {
        loginUser();
    }

    return (
        <div className={classes.Wrapper}>
            <form className={classes.Form} onSubmit={onSubmit}>
                <h2>Войти в кабинет</h2>
                <p>Для входа  необходимо авторизоваться:</p>

                <Group error={errors.login} label="Логин">
                    <Input
                        name="login"
                        value={values.login}
                        placeholder="Введите логин"
                        onChange={onChange}
                    />
                </Group>
                <Group error={errors.password} label="Пароль">
                    <Input
                        name="password"
                        type="password"
                        value={values.password}
                        placeholder="Введите пароль"
                        onChange={onChange}
                    />
                </Group>
                <Group>
                    <Button>Войти</Button>
                </Group>
            </form>
        </div>
    )
};

export default Login;