import Moment from "react-moment";
import classes from "./Date.module.scss";
import { daysLeft, daysCount } from '../../helpers/helpers';

export const DateEnd = ({date: {disabledAt, activatedAt}, pay: { paid }}) => {
    if (activatedAt) {
        return (
            <div className={classes.Item}>
                <h3><Moment format="DD.MM.YYYY">{disabledAt}</Moment></h3>
                {daysLeft(disabledAt, activatedAt)}
            </div>
        )
    } else {
        return (
            <div className={classes.Item}>
                <i className={classes.Orange}/>
                <p className={paid ? classes.Green : classes.Orange}>
                    { paid ? 'Выдайте пароль' : 'Ожидаем оплату' }
                </p>
            </div>
        )
    }
};


export const DateStart = ({date: { disabledAt, activatedAt }, pay: { paid }}) => {
    if (activatedAt) {
        return (
            <div className={classes.Item}>
                <h3><Moment format="DD.MM.YYYY">{activatedAt}</Moment></h3>
                <p className={classes.Active}>{daysCount(disabledAt, activatedAt)}</p>
            </div>
        )
    } else {
        return (
            <div className={classes.Item}>
                <i className={classes.Orange}/>
                <p className={paid ? classes.Paid : classes.Orange}>
                    { paid ? 'Выдайте пароль' : 'Ожидаем оплату' }
                </p>
            </div>
        )
    }
};



export const DatePay = ({date: {disabledAt, paidAt}}) => {
    if (paidAt) {
        return (
            <div className={classes.Item}>
                <h3><Moment format="DD.MM.YYYY">{paidAt}</Moment></h3>
                <p className={classes.Active}>{daysCount(disabledAt, paidAt)}</p>
            </div>
        )
    } else {
        return (
            <div className={classes.Item}>
                <i className={classes.Orange}/>
                <p className={classes.Orange}>Ожидаем оплату</p>
            </div>
        )
    }
};


export const DateCreated = (createdAt) => {
    return (
       <Moment format="DD.MM.YYYY">{createdAt}</Moment>
    )
};