import React, { useContext } from 'react';
import classnames from 'classnames';
import classes from './Navbar.module.scss';
import logo from '../../assets/img/logo.svg';
import inform from '../../assets/img/inform.svg';
import users from '../../assets/img/users.svg';
import instruct from '../../assets/img/instruct.svg';
import logoutIcon from '../../assets/img/logout.svg';
import signalIcon from '../../assets/img/signal.svg';
import moneyIcon from '../../assets/img/money.svg';
import settingsIcon from '../../assets/img/settings.svg';
import { NavLink } from "react-router-dom";
import { AuthContext } from '../../context/auth';

const Navbar = () => {
    const { user, logout } = useContext(AuthContext);

    if (!user) {
        return false;
    }


    return (
        <div className={classes.Wrapper}>
            <NavLink className={classes.Logo} to="/home">
                <img src={logo} alt=""/>
            </NavLink>
            <div className={classes.Menu}>
                <NavLink className={classes.Link} to="/home">
                    <img src={inform} alt=""/>
                    <span>Информационная панель</span>
                </NavLink>
                <NavLink className={classes.Link} to="/users">
                    <img src={users} alt=""/>
                    <span>Пользователи</span>
                </NavLink>
                <NavLink className={classes.Link} to="/instructions">
                    <img src={instruct} alt=""/>
                    <span>Инструкции</span>
                </NavLink>
                <NavLink className={classes.Link} to="/stations">
                    <img src={signalIcon} alt=""/>
                    <span>Базовые станции</span>
                </NavLink>

                <NavLink className={classes.Link} to="/csv">
                    <img src={moneyIcon} alt=""/>
                    <span>Отчеты</span>
                </NavLink>


                <NavLink className={classes.Link} to="/settings">
                    <img src={settingsIcon} alt=""/>
                    <span>Настройки</span>
                </NavLink>

                <button className={classnames(classes.Link, classes.Logout)} onClick={() => logout()}>
                    <img src={logoutIcon} alt=""/>
                    <span>Выйти</span>
                </button>
            </div>
        </div>
    )
}

export default Navbar;