import React from 'react';
import classes from './ButtonIcon.module.scss';
import blockIcon from '../../../assets/img/block.svg';
import deleteIcon from '../../../assets/img/delete.svg';
import editIcon from '../../../assets/img/edit.svg';
import infoIcon from '../../../assets/img/info.svg';
import passwordIcon from '../../../assets/img/password.svg';
import archiveIcon from '../../../assets/img/archive.svg';
import migrateIcon from '../../../assets/img/migrate.svg';

const ButtonIcon = ({color = 'grey', type, onClick, show, tooltip }) => {

    const cls = [classes.Button];

    const icons = {
        edit: editIcon,
        info: infoIcon,
        block: blockIcon,
        delete: deleteIcon,
        archive: archiveIcon,
        password: passwordIcon,
        migrate: migrateIcon
    };

    switch (color) {
        case "green":
            cls.push(classes.Green)
            break;

        default:
            cls.push(classes.Grey);
            break;

    }

    if (!show) return false;

    return (
        <button className={cls.join(' ')} onClick={onClick} data-tip={tooltip}><img src={icons[type]} alt=""/></button>
    )
}

export default ButtonIcon;