import React from "react";
import ReactTooltip from 'react-tooltip';
import ButtonIcon from "../UI/ButtonIcon/ButtonIcon";
import { useModal } from "../Modal/ModalContext";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
    DELETE_SUBSCRIBE,
    DELETE_INSTRUCTION,
    DELETE_USER,
    ARCHIVE_SUBSCRIBE,
    DELETE_STATION
} from '../../graphql/graphql';


const Controls = (
    {
        id,
        file,
        deviceId,
        available,
        paidStatus,
        controls = {}
    }) => {

    let history = useHistory();
    const {show, hide} = useModal();
    const path = process.env.NODE_ENV === 'production' ? 'https://api.geokurs.kz' : 'http://localhost:5000';
    const [deleteSubscribe] = useMutation(DELETE_SUBSCRIBE, {
        variables: {
            id
        },
        onCompleted: () => {
            hide();
        }
    });

    const [deleteStation] = useMutation(DELETE_STATION, {
        variables: {
            stationId: id
        },
        onCompleted: () => {
            hide();
        }
    });

    const [archiveSubscribe] = useMutation(ARCHIVE_SUBSCRIBE, {
        variables: {
            id
        },
        onCompleted: () => {
            hide();
        }
    });


    const [deleteInstruction] = useMutation(DELETE_INSTRUCTION, {
        variables: {
            id
        },
        onCompleted: () => {
            hide();
        }
    });

    const [deleteUser] = useMutation(DELETE_USER, {
        variables: {
            id
        },
        onCompleted: () => {
            hide();
        }
    });


    return (
        <div>
            <ReactTooltip />

            <ButtonIcon
                color="green"
                type="password"
                tooltip="Выдать пароль"
                show={controls.passwordSubscribe && !available && paidStatus}
                onClick={() => show('passwordSendler', { id })}>Выдать
                пароль</ButtonIcon>

            <ButtonIcon
                type="info"
                tooltip="Полная информация"
                show={controls.infoSubscribe}
                onClick={() => history.push(`/subscribe/${id}`)}
            />


            <ButtonIcon
                type="info"
                tooltip="Полная информация"
                show={controls.infoUser}
                onClick={() => history.push(`/user/${id}`)}
            />

            <ButtonIcon
                type="edit"
                tooltip="Редактировать"
                show={controls.editSubscribe}
            />

            <ButtonIcon
                type="migrate"
                tooltip="Переместить прибор"
                show={controls.migrateSubscribe}
                onClick={() => {
                    show('migrateConfirm', {
                        id
                    })
                }}
            />

            <ButtonIcon
                type="edit"
                tooltip="Продление подписки"
                show={controls.prolongSubscribe}
                onClick={() => {
                    show('prolongSubscribe', {
                        id
                    })
                }}
            />

            <ButtonIcon
                type="archive"
                tooltip="Архивировать"
                show={controls.archive}
                onClick={() => {
                    show('deleteConfirm', {
                        id,
                        title: `Вы действительно хотите архивировать подписку?`,
                        deleteHandler: () => archiveSubscribe()
                    })
                }}
            />

            <ButtonIcon
                type="delete"
                tooltip="Удалить"
                show={controls.deleteSubscribe}
                onClick={() => {
                    show('deleteConfirm', {
                        id,
                        title: `Вы действительно хотите удалить подписку ${deviceId}?`,
                        deleteHandler: () => deleteSubscribe()
                    })
                }}
            />

            <ButtonIcon
                type="delete"
                tooltip="Удалить"
                show={controls.deleteStation}
                onClick={() => {
                    show('deleteConfirm', {
                        id,
                        title: `Вы действительно хотите удалить станцию?`,
                        deleteHandler: () => deleteStation()
                    })
                }}
            />

            <ButtonIcon
                show={controls.infoInstruction}
                type="info"
                onClick={() => window.open(`${path}/uploads/bs_pdf/${file}`, '_blank')}
            />

            <ButtonIcon
                type="edit"
                show={controls.editInstruction}
            />

            <ButtonIcon
                type="delete"
                tooltip="Удалить"
                show={controls.deleteInstruction}
                onClick={() => {
                    show('deleteConfirm', {
                        id,
                        title: `Вы дествительно хотите удалить инструкцию?`,
                        deleteHandler: () => deleteInstruction()
                    })
                }}
            />

            <ButtonIcon
                type="delete"
                tooltip="Удалить"
                show={controls.deleteUser}
                onClick={() => {
                    show('deleteConfirm', {
                        id,
                        title: `Вы дествительно хотите удалить пользователя?`,
                        deleteHandler: () => deleteUser()
                    })
                }}
            />
        </div>
    )
}


export default Controls;