import React from 'react';
import classes from './Button.module.scss';


const Button = ({ children, className = [], onClick, disabled = false, type = "green", size = "big", icon }) => {
    const cls = [classes.Button, ...className];

    switch (type) {
        case "green":
            cls.push(classes.Green)
            break;
        case "random":
            cls.push(classes.Random)
            break;
        case "red":
            cls.push(classes.Red)
            break;
        default:
            cls.push(classes.Green);
            break;
    }

    switch (size) {
        case "small":
            cls.push(classes.Small)
            break;
        case "normal":
            cls.push(classes.Normal)
            break;
        case "big":
            cls.push(classes.Big)
            break;
        default:
            cls.push(classes.Green);
            break;
    }
    return (
        <button className={cls.join(' ')} onClick={onClick} disabled={disabled}>
            <img src={icon} alt="" />
            { children }
        </button>
    )
};

export default Button;