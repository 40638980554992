import React, {useState} from 'react';
import Page from '../../components/Page/Page';
import classes from './Csv.module.scss';
import {useMutation} from "@apollo/client";
import {GET_CSV} from "../../graphql/graphql";
import Loader from "react-loader-spinner";
import Group from "../../components/UI/Group/Group";
import DatePicker from "react-datepicker";
import Button from "../../components/UI/Button/Button";
import Select from "react-select";

const Csv = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [payMethod, setPayMethod] = useState('');
    const [value, setValue] = useState({});

    const [link, setLink] = useState('');

    const [getCsv, { loading }] = useMutation(GET_CSV, {
        update(proxy, {data}) {
            setLink(data.createCsvSubscribes);
        }
    });


    const onSubmit = (event) => {
        event.preventDefault();
        console.log({
            startDate: value.startDate,
            endDate: value.endDate,
            payMethod: payMethod
        });
        getCsv({
            variables: {
                startDate: value.startDate,
                endDate: value.endDate,
                payMethod: payMethod
            }
        });
    }


    if (loading) return <Loader
        className="loading"
        type="Circles"
        color="#284255"
        height={50}
        width={50}
        timeout={3000}
    />;

    return (
        <Page title="Отчет по оплатам">
            <div className={classes.Wrapper}>
                <form onSubmit={onSubmit} className={classes.Form}>
                    <Group label="Укажите начальную дату">
                        <DatePicker
                            dateFormat="dd.MM.yyyy"
                            selected={startDate}
                            onChange={date => {
                                setStartDate(date);
                                setValue({
                                    ...value,
                                    ['startDate']: new Date(date).toISOString()
                                });

                                console.log(value);
                            }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            locale="ru"
                        />
                    </Group>

                    <Group label="Укажите конечную дату">
                        <DatePicker
                            dateFormat="dd.MM.yyyy"
                            selected={endDate}
                            onChange={date => {
                                setEndDate(date);
                                setValue({
                                    ...value,
                                    ['endDate']: new Date(date).toISOString()
                                });

                                console.log(value);
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            locale="ru"
                        />
                    </Group>

                    <Group label="Способы оплаты">
                        <Select
                            isLoading={loading}
                            isSearchable={false}
                            classNamePrefix="select"
                            maxMenuHeight={200}
                            placeholder="Укажите способы оплаты"
                            onChange={event => {
                                setPayMethod(event.value);
                            }}
                            options={
                                [
                                    {
                                        label: 'Оплаты картой',
                                        value: 'CARD'
                                    },
                                    {
                                        label: 'Оплаты по счету',
                                        value: 'INVOICE'
                                    },
                                    {
                                        label: 'Оплата менеджером',
                                        value: 'MANAGER'
                                    },
                                    {
                                        label: 'Все способы оплаты',
                                        value: 'ALL'
                                    }
                                ]
                            }
                        />
                    </Group>
                    <Group>
                        <Button size="normal">Сгенерировать .csv</Button>
                    </Group>

                    {link && <a download={true} target="_blank" href={link}>Скачать отчет</a>}
                </form>
            </div>
        </Page>
    )
}

export default Csv;


