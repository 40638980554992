import React, { useState } from 'react';
import Page from "../../components/Page/Page";
import classes from './Settings.module.scss';
import Group from "../../components/UI/Group/Group";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import {Input} from "../../components/UI/Input/Input";
import {useForm} from "../../hoc/form";
import {gql, useMutation, useQuery} from "@apollo/client";
import {REGISTER_USER} from "../../graphql/graphql";
import Button from "../../components/UI/Button/Button";


const Settings = () => {
    const [errors, setErrors] = useState({});
    const {onChange, onSubmit, values, updateState} = useForm(promoCallback, {
        login: '',
        password: '',
        status: ''
    });


    const { loading, data: { getBsSettings: settings } = {} } = useQuery(FETCH_SETTINGS, {
        onCompleted: () => {
            console.log(settings);
            updateState({ ...settings.promo });
        }
    });



    const [promoUpdate] = useMutation(UPDATE_SETTINGS, {
        update(_, {data}) {

        },
        onError(err) {
            setErrors(err.graphQLErrors[0].extensions.exception.errors);
            console.log(err.graphQLErrors[0].extensions.exception.errors)
        },
    });

    function promoCallback() {
        console.log(values)
        promoUpdate({
            variables: values
        });
    }

    return (
        <Page
            title="Настройки"
        >
            <div className={classes.Nav}>
               <button className={classes.Active}>Промо</button>
            </div>
            <div className={classes.Wrapper}>
                <div className={classes.Item}>
                    <h3>Активация промо-акции:</h3>
                    <form onSubmit={onSubmit}>
                        <Group>
                            <Checkbox
                                name="status"
                                label="Статус промо-акции"
                                onChange={onChange}
                                checked={values.status}
                            />
                        </Group>
                        <Group label="Логин" error={errors.login}>
                            <Input
                                name="login"
                                value={values.login}
                                placeholder="Введите login пользователя"
                                onChange={onChange}
                            />
                        </Group>
                        <Group label="Пароль" error={errors.password}>
                            <Input
                                name="password"
                                value={values.password}
                                placeholder="Введите пароль пользователя"
                                onChange={onChange}
                            />
                        </Group>
                        <Group>
                            <Button size="normal">Сохранить</Button>
                        </Group>
                    </form>
                </div>
            </div>
        </Page>
    )
}

const FETCH_SETTINGS = gql `
    {
        getBsSettings {
            promo {
                tarif
                login
                password
                status  
            }
        }
    }
`;

const UPDATE_SETTINGS = gql`
    mutation updateSettings(
        $login: String!
        $password: String!
        $status: Boolean!
    ) {
        updateSettings(
            login: $login
            password: $password
            status: $status
        ) 
    }
`;


export default Settings;