import classes from "./Modal.module.scss";
import closeIcon from '../../assets/img/close.svg';
import { motion } from "framer-motion";
import { useModal } from "./ModalContext";

const ModalWrapper = ({ className = [], children, style, title, desc, close = true }) => {
    const { hide } = useModal();
    const cls = [classes.Item, className];

    return (
        <motion.div
            className={cls.join(' ')}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            style={{...style}}
        >
            { title && <h1 className={classes.Title}>{title}</h1> }
            { desc && <p className={classes.Desc}>{desc}</p> }
            { children }

            { close && <button className={classes.Close} onClick={hide}>
                <img src={closeIcon} alt=""/>
            </button> }
        </motion.div>
    )
};


export default ModalWrapper;