import React from 'react';
import classes from './ModalInstruction.module.scss';
import ModalWrapper from "../ModalWrapper";
import Group from "../../UI/Group/Group";
import Button from "../../UI/Button/Button";
import { Input, InputFile } from "../../UI/Input/Input";
import { useForm } from "../../../hoc/form";
import { useMutation } from "@apollo/client";
import { useModal } from "../ModalContext";
import { CREATE_INSTRUCTION } from "../../../graphql/graphql";


const ModalInstruction = () => {
    const { hide } = useModal();

    const {onChange, onSubmit, fileHandler, values} = useForm(passwordSendCallback, {
        label: '',
        file: { }
    });

    const [createInstruction] = useMutation(CREATE_INSTRUCTION, {
        update(_, {data}) {
            hide();
        }
    });

    function passwordSendCallback() {
        createInstruction({
            variables: values
        });
    }

    return (
        <ModalWrapper
            title="Добавить инструкцию"
            desc="Для добавления инструкции введите все данные:"
        >
            <form className={classes.Form} onSubmit={onSubmit}>
                <Group label="Название инструкции">
                    <Input
                        name="label"
                        value={values.label}
                        placeholder="Введите название инструкции"
                        onChange={onChange}
                    />
                </Group>
                <InputFile
                    name="file"
                    label="Файл инструкции"
                    value={values.file.name}
                    onChange={fileHandler}
                />
                <Group>
                    <Button size="normal">Добавить инструкцию</Button>
                </Group>
            </form>
        </ModalWrapper>
    )
}


export default ModalInstruction;