import React, {useState} from 'react';
import classes from "./Stations.module.scss";
import Page from "../../components/Page/Page";
import Controls from "../../components/Controls/Controls";
import {useQuery} from "@apollo/client";
import {FETCH_STATIONS} from "../../graphql/graphql";
import {Table, Td, Tr} from "../../components/UI/Table/Table";
import Button from "../../components/UI/Button/Button";
import {useModal} from "../../components/Modal/ModalContext";


const Stations = () => {
    const [page, setPage] = useState(1);
    const { show } = useModal();

    const { loading, data: { getBsStations: stations } = {}, fetchMore } = useQuery(FETCH_STATIONS, {
        pollInterval: 1500,
        variables: {
            page: page,
            limit: 15
        }
    });


    return (
        <Page
            title="Базовые станции"
            callback={setPage}
            fetchMore={fetchMore}
            loading={loading}
            paginator={!loading && stations.paginator}
            controls={
                <Button
                    size="normal"
                    onClick={() => show('createStation')}>
                    Добавить станцию
                </Button>
            }>
            <Table value={[
                {name: "index", label: '№'},
                {name: "code", label: 'Код'},
                {name: "city", label: 'Населеный пункт'},
                {name: "position", label: 'Расположение' },
                {name: "cords", label: 'Координаты'},
                {name: "satellite", label: 'Спутниковые группировки'},
                {name: "status", label: 'Статус'},
                {name: "controls", label: 'Действия'}
            ]}>
                { stations && stations.data.map((item, index) => {
                    return (
                        <Tr key={index}>
                            <Td>{stations.paginator.slNo + index}</Td>
                            <Td>{item.code}</Td>
                            <Td>{item.city}</Td>
                            <Td>
                                Широта: {item.position.lat}"<br />
                                Долгота: {item.position.lon}"<br />
                                Высота: {item.position.alt} м
                            </Td>
                            <Td>
                                N: {item.cords.n}<br />
                                E: {item.cords.e}
                            </Td>
                            <Td>{item.satellite}</Td>
                            <Td>
                                { item.status === true ? (
                                    <><span className={classes.Online} /> Работает</>
                                ) : (
                                    <><span className={classes.Offline} /> Временно отключена</>
                                ) }
                            </Td>
                            <Td type="controls">
                                <Controls
                                    id={item.id}
                                    controls={{
                                        deleteStation: true
                                    }}
                                />
                            </Td>
                        </Tr>
                    )
                })}
            </Table>
        </Page>
    )
};

export default Stations;