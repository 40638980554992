import React, { useContext } from 'react';
import classes from './Page.module.scss';
import { motion } from "framer-motion";
import { AuthContext } from "../../context/auth";
import Paginator from "../Paginator/Paginator";
import Loader from "react-loader-spinner";

const Page = (props) => {
    const { user } = useContext(AuthContext);

    return (
        <motion.div className={classes.Main} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className={classes.Header}>
                <h1>{props.title}</h1>
                <div className={classes.User}>
                    <ul>
                        <li><span>User-id:</span>  {user.login}</li>
                        <li><span>Менеджер: </span>  {user.name}</li>
                    </ul>
                </div>
            </div>
            { (props.controls || props.paginator) &&
                <div className={[classes.Controls, props.position === 'end' && classes.ControlsEnd].join(' ')}>
                    {props.controls && props.controls}
                    {props.paginator && props.fetchMore &&
                        <Paginator
                            fetchMore={props.fetchMore}
                            paginator={props.paginator}
                            setPage={props.callback}
                        />
                    }
                </div>
            }
            <div className={classes.Content}>
                { props.loading &&
                    <Loader
                        className="loading"
                        type="Circles"
                        color="#284255"
                        height={50}
                        width={50}
                        timeout={3000}
                    />
                }

                { !props.loading &&
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                        {props.children}
                    </motion.div>
                }
            </div>
        </motion.div>
    )
}

export default Page;