import React, { useState } from 'react';
import classes from './ModalMigrate.module.scss';
import User from "../../UI/User/User";
import Group from "../../UI/Group/Group";
import ModalWrapper from "../ModalWrapper";
import Button from "../../UI/Button/Button";
import { useMutation } from "@apollo/client";
import { useModal } from "../ModalContext";
import { MIGRATE_DEVICE } from "../../../graphql/graphql";

const ModalMigrate = () => {
    const { hide, modalValues, values } = useModal();
    const [errors, setErrors] = useState({});

    const [migrateDevice] = useMutation(MIGRATE_DEVICE, {
        update(_, {data}) {
            hide();
        },
        onError(err) {
            setErrors(err.graphQLErrors[0].extensions.exception.errors);
            console.log(err.graphQLErrors[0].extensions.exception.errors)
        },
    });

    const onSubmit = (event) => {
        event.preventDefault();
        migrateDevice({
            variables: {
                deviceID: modalValues.id,
                userID: values.userID.id
            }
        });
    }

    return (
        <ModalWrapper
            title={`К какому пользователю\n переместить прибор?`}
            desc="Выберите пользователя из выпадающего списка:"
        >
            <form className={classes.Form} onSubmit={onSubmit} autoComplete="off">
                <User
                    name="userID.id"
                    placeholder="Выберите пользователя из списка"
                    error={errors.userID}
                />
                <Group>
                    <Button size="normal">Переместить прибор</Button>
                </Group>
            </form>
        </ModalWrapper>
    )
}


export default ModalMigrate;