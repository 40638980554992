import { useState } from 'react';

export const useForm = (callback, initialState = {}) => {
    const [values, setValues] = useState(initialState);

    const onChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        setValues({ ...values, [name]: value });
    }

    const onSubmit = (event) => {
        event.preventDefault();
        callback();
    }

    const fileHandler = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setValues({
            ...values,
            file: file
        });
    }

    const updateState = (data) => {
        setValues({ ...data });
    }

    return {
        onSubmit,
        onChange,
        fileHandler,
        updateState,
        values
    }
}