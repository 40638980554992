import React from 'react';
import classes from "../Label/Label.module.scss";


const Label = ({ htmlForm, label}) => {
    return (
        <label htmlFor={htmlForm} className={classes.Label}>{label}:</label>
    )
}

export default Label;