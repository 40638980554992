import React from 'react';
import Select from "react-select";
import Group from "../Group/Group";
import classes from "../Input/Input.module.scss";
import { useQuery } from "@apollo/client";
import { useModal } from "../../Modal/ModalContext";
import { FETCH_USERS } from "../../../graphql/graphql";


const User = ({ error, disabled, name, placeholder }) => {
    const { inputHandler } = useModal();
    const { loading, data: { getUsers: users } = {} } = useQuery(FETCH_USERS);

    return (
        <Group label="Пользователь" error={error}>
            <Select
                isLoading={loading}
                isDisabled={disabled && true}
                className={error && classes.ErrorSelect}
                classNamePrefix="select"
                placeholder={placeholder}
                options={users && users.map(item => {
                    return {
                        ...item,
                        name: name,
                        label: item.login,
                        value: item.id
                    }
                })}
                onChange={(event) => inputHandler(event)}
            />
        </Group>
    )
};

export default User;
