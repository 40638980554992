import React, { useState } from 'react';
import Page from '../../components/Page/Page';
import Button from "../../components/UI/Button/Button";
import { useQuery } from "@apollo/client";
import { useModal } from "../../components/Modal/ModalContext";
import { GET_USERS } from "../../graphql/graphql";
import { Table, Td, Tr } from "../../components/UI/Table/Table";
import Controls from "../../components/Controls/Controls";
import Search from "../../components/Search/Search";
import {useSearch} from "../../context/search";


const Users = () => {
    const { show } = useModal();
    const { searchValue } = useSearch();
    const [page, setPage] = useState(1);

    const { loading, data: { getUsersPage: users } = {}, fetchMore } = useQuery(GET_USERS, {
        variables: {
            page: page,
            limit: 15,
            search: searchValue
        }
    });

    return (
        <Page
            title="Пользователи"
            callback={setPage}
            fetchMore={fetchMore}
            loading={loading}
            paginator={!loading && users.paginator}
            controls={
                  <>
                      <Button
                          size="normal"
                          onClick={() => show('createUser')}>
                          Добавить пользователя
                      </Button>
                      <Search
                          setPage={setPage}
                          placeholder="Поиск: имя, логин, компания, email"
                      />
                  </>
            }>
            { users &&
                <Table value={[
                    {name: "id", label: 'ID-пользователя'},
                    {name: "name", label: 'Контакт'},
                    {name: "company", label: 'Компания', type: 'company' },
                    {name: "city", label: 'Город'},
                    {name: "phone", label: 'Номер телефона'},
                    {name: "email", label: 'Email'},
                    {name: "subscribtions", label: 'Кол-во подписок'},
                    {name: "controls", label: 'Действия'}
                ]}>
                    { users.data.map((item, index) => {
                        return (
                            <Tr key={index}>
                                <Td>{item.login}</Td>
                                <Td>{item.lastname} {item.name}</Td>
                                <Td>{item.company}</Td>
                                <Td>{item.city}</Td>
                                <Td>{item.phone}</Td>
                                <Td>{item.email}</Td>
                                <Td>{item.subscriptions.length}</Td>
                                <Td type="controls">
                                    <Controls
                                        id={item.id}
                                        controls={{
                                            infoUser: true,
                                            deleteUser: true
                                        }}
                                    />
                                </Td>
                            </Tr>
                        )
                    })}
                </Table>
            }
        </Page>
    )
}


export default Users;