import React from 'react';
import classes from '../Modal.module.scss';
import Plan from "../../UI/Plan/Plan";
import User from "../../UI/User/User";
import Brand from "../../UI/Brand/Brand";
import Group from "../../UI/Group/Group";
import Button from "../../UI/Button/Button";
import Stations from "../../UI/Stations/Stations";
import Status from "../../UI/Status/Status";
import DatePick from "../../UI/DatePick/DatePick";
import ModalWrapper from "../ModalWrapper";
import { useMutation } from '@apollo/client';
import { useModal } from "../ModalContext";
import { Input, InputFile } from "../../UI/Input/Input";
import { useHistory } from "react-router-dom";
import { CREATE_SUBSCRIBE } from "../../../graphql/graphql";



const ModalSubscribeCreate = () => {
    const history = useHistory();
    const { hide, values, inputHandler, fileHandler, setErrors, errors } = useModal();

    const [createSubscribe, {loading}] = useMutation(CREATE_SUBSCRIBE, {
        update(proxy) {
            hide();
            history.go(`/paid`);
        },
        onError(err) {
            setErrors({ ...errors, ...err.graphQLErrors[0].extensions.exception.errors});
        },
        variables: values
    });


    const onSubmit = (event) => {
        event.preventDefault();
        console.log(values);
        createSubscribe();
    }


    return (
        <ModalWrapper
            title="Добавить приемник"
            desc="Для добавления приемника введите все данные:"
        >
            <form encType="multipart/form-data" className={loading ? `${classes.Form} ${classes.FormLoading}` : classes.Form} onSubmit={onSubmit}>

                <span className={classes.GeneralError}>{errors.general}</span>
                    <User
                        name="userID.id"
                        placeholder="Выберите пользователя из списка"
                        error={errors.userID}
                    />
                    <Brand
                        name="device.brand"
                        error={errors.brand}
                        placeholder="Выберите марку из списка"
                        disabled={values.device.brand && true}
                    />

                    <Group label="Модель приемника" error={errors.model}>
                        <Input
                            name="device.model"
                            group="device"
                            type="text"
                            value={values.device.model}
                            placeholder="Укажите модель приемника"
                            onChange={(event) => inputHandler(event.target)}
                        />
                    </Group>

                    <Group label="Серийный номер" error={errors.serial}>
                        <Input
                            name="device.serial"
                            type="text"
                            value={values.device.serial}
                            placeholder="Укажите серийный номер приемника"
                            onChange={(event) => inputHandler(event.target)}
                        />
                    </Group>


                    <InputFile
                        label="Фото приемника"
                        name="file"
                        value={values.file.name}
                        error={errors.file}
                        onChange={(event) => fileHandler(event)}
                    />

                    <Plan
                        name="plan"
                        label="Выберите тариф"
                        placeholder="Выберите тарифный план"
                        error={errors.plan}
                    />

                    <Stations
                        name="station.items"
                        placeholder="Выберите базу"
                        error={errors.station}
                    />

                    <DatePick />

                    <Status
                        name="status"
                    />
                    <Group>
                        <Button className={classes.FormSubmit}>Продолжить</Button>
                    </Group>
                </form>
        </ModalWrapper>
    )
};


export default ModalSubscribeCreate;
