import React, { useState, useEffect } from "react";
import classes from './Subscribe.module.scss';
import Moment from "react-moment";
import Page from "../../components/Page/Page";
import Stations from "../../components/Stations/Stations";
import ModalImage from "react-modal-image";
import { useQuery, useLazyQuery } from "@apollo/client";
import {Link, useParams} from "react-router-dom";
import { DateEnd, DateStart } from "../../components/Date/Date";
import { GET_USER_SUBSCRIBE, GET_USER } from "../../graphql/graphql";


const PageCurrentSubscribe = () => {
    const { id } = useParams();
    const [fetchUser, setFetchUser] = useState('');

    const path = process.env.NODE_ENV === 'production' ? 'https://api.geokurs.kz' : 'http://localhost:5000';

    const [loadUser, { loadingUser, data: { getUserById: user } = {} }] = useLazyQuery(GET_USER);
    const { data: { getBsSubscription: subscribe, getBsInvoice: invoice = {} } = {} } = useQuery(GET_USER_SUBSCRIBE, {
        variables: { id },
        onCompleted: () => {
            setFetchUser(subscribe.user)
        }
    });

    useEffect(() => {
        loadUser({
            variables: {
                id: fetchUser
            }
        });
    }, [ fetchUser, loadingUser ])


    return (
        <>
            { subscribe && user && (
                <Page title={`Подписка: ${subscribe.device.id}`}>
                    <div className={classes.Wrapper}>
                        <div className={classes.Left}>
                            <div className={classes.LeftItem}>
                                <div className={classes.Info}>
                                    <div className={classes.Item}>
                                        <h3>Приемник:</h3>
                                        <ul>
                                            <li><span>Наименование:</span> {subscribe.device.brand} {subscribe.device.model}</li>
                                            <li><span>Серийный номер:</span> {subscribe.device.serial}</li>
                                            <li><span>Дата регистрации:</span> <Moment format="DD.MM.YYYY (HH:MM)">{subscribe.date.createdAt}</Moment></li>
                                        </ul>
                                    </div>
                                    { subscribe.available &&
                                        <div className={classes.Item}>
                                            <h3>Доступы:</h3>
                                            <ul>
                                                <li><span>Логин:</span> {subscribe.available.login}</li>
                                                <li><span>Пароль:</span> {subscribe.available.password}</li>
                                            </ul>
                                        </div>
                                    }
                                    <div className={classes.Item}>
                                        <h3>Тариф:</h3>
                                        <ul>
                                            <li><span>Тарифный план:</span> {subscribe.plan.label}</li>
                                            <li><span>Стоимость:</span> {subscribe.plan.amount} тг.</li>
                                        </ul>
                                    </div>

                                    <div className={classes.Item}>
                                        <h3>Оплата:</h3>
                                        <ul>
                                            <li><span>Способ оплаты:</span> {subscribe.pay.method}</li>
                                            <li><span>Cтатус оплаты:</span> {subscribe.pay.paid ? 'Оплачен' : 'Не оплачен'}</li>
                                            <li><span>Дата оплаты:</span> <Moment format="DD.MM.YYYY (HH:MM)">{subscribe.date.paidAt}</Moment></li>
                                        </ul>
                                    </div>

                                    <div className={classes.Item}>
                                        <h3>Базовые станции:</h3>
                                        <Stations
                                            stations={subscribe.station}
                                        />
                                    </div>

                                    { subscribe.available && <div className={classes.Item}>
                                        <h3>Подписка:</h3>
                                        <div className={classes.Date}>
                                            <DateStart
                                                pay={subscribe.pay}
                                                date={subscribe.date}
                                            />
                                            <DateEnd
                                                pay={subscribe.pay}
                                                date={subscribe.date}
                                            />
                                        </div>
                                    </div> }
                                </div>
                                {user && <div className={classes.Pay}>
                                    <div className={classes.Item}>
                                        <h3>Пользователь:</h3>
                                        <ul>
                                            <li><span>ID:</span> {user.login}</li>
                                            <li><span>Город:</span> {user.city}</li>
                                            <li><span>Компания:</span> {user.company}</li>
                                            <li><span>ФИО:</span> {user.name} {user.lastname}</li>
                                            <li><span>Email:</span> {user.email}</li>
                                            <li><span>Номер телефона:</span> {user.phone}</li>
                                            <li><span>Дата регистрации:</span> <Moment
                                                format="DD.MM.YYYY (HH:MM)">{user.createdAt}</Moment></li>
                                        </ul>
                                        <Link to={`/user/${user.id}`}>Перейти в кабинет пользователя</Link>
                                    </div>
                                    { invoice && (
                                        <div className={classes.Item}>
                                            <h3>Банковские реквизиты:</h3>
                                            <div className={classes.Date}>
                                                <ul>
                                                    <li><span>БАНК:</span> {invoice.bank}</li>
                                                    <li><span>БИН/ИИН:</span> {invoice.bin}</li>
                                                    <li><span>Расчетный счет:</span> {invoice.inv}</li>
                                                    {invoice.address.length > 0 && <li><span>Юр. адрес:</span> {invoice.address}</li>}
                                                </ul>
                                            </div>
                                        </div>
                                    ) }
                                </div> }
                            </div>
                        </div>
                        <div className={classes.Right}>
                            <ModalImage
                                small={`${path}/uploads/bs_devices/${subscribe.file}`}
                                large={`${path}/uploads/bs_devices/${subscribe.file}`}
                            />
                        </div>
                    </div>
                </Page>
            )}
        </>
    )
};

export default PageCurrentSubscribe;