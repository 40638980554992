import React, {useState, useEffect} from 'react';
import Select from "react-select";
import Group from "../Group/Group";
import classes from "../Input/Input.module.scss";
import { useQuery } from "@apollo/client";
import { useModal } from "../../Modal/ModalContext";
import { FETCH_STATIONS_OPTIONS } from "../../../graphql/graphql";


const Stations = ({ name, placeholder, error }) => {
    const [station, setStation] = useState([]);
    const [select, setSelect] = useState([]);

    const {stationHandler, planCount} = useModal();
    const {loading, data: { getBsStations: stations } = {}} = useQuery(FETCH_STATIONS_OPTIONS, {
        variables: {
            page: 1,
            limit: 100
        }
    });


    useEffect(() => {
        const stationItem = [];

        for (let i = 0; i < planCount; i++) {
            stationItem.push({
                label: `База №${i + 1}`,
                placeholder: 'Выберите базу'
            });
        }

        setStation([...stationItem]);
    }, [planCount]);


    const selectStation = (event, i) => {
        const selector = [...select];
        selector[i] = {...event};

        setSelect([...selector]);
    };


    return (
        <Group flex={planCount >= 1} full={planCount > 1 && true}>
            {station && station.map((item, index) => {
                return (
                    <Group label={item.label} error={null} key={index}>
                        <Select
                            isLoading={loading}
                            isSearchable={false}
                            value={select[index] || ''}
                            classNamePrefix="select"
                            maxMenuHeight={200}
                            placeholder={placeholder}
                            className={error && classes.ErrorSelect}
                            options={stations.data.map(item => {
                                return {
                                    id: index,
                                    name: name,
                                    value: item.id,
                                    label: `${item.city} (${item.code})`,
                                    data: {
                                        code: item.code,
                                        label: item.city
                                    }
                                }
                            })}
                            onChange={event => {
                                selectStation(event, index)
                                stationHandler(event)
                            }}
                        />
                    </Group>
                )
            })}
            <span className={classes.Message}>{error}</span>
        </Group>
    )
};

export default Stations;


