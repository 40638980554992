import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from "./routes";
import Navbar from "./components/Navbar/Navbar";
import Modal from './components/Modal/Modal';
import AuthVerifyComponent from './hoc/AuthVerify';
import { AuthProvider } from './context/auth';
import { ModalProvider } from './components/Modal/ModalContext';

function App() {
    return (
        <AuthProvider>
            <Router>
                <ModalProvider>
                    <Navbar />
                    <Modal />
                    <Routes />
                    <AuthVerifyComponent />
                </ModalProvider>
            </Router>
        </AuthProvider>
    );
}

export default App;
