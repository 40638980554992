import React from 'react';
import classes from './ModalPassword.module.scss';
import random from '../../../assets/img/random.svg';
import randomstring from "randomstring";
import ModalWrapper from "../ModalWrapper";
import Group from "../../UI/Group/Group";
import Button from "../../UI/Button/Button";
import { Input } from "../../UI/Input/Input";
import { useForm } from "../../../hoc/form";
import { useMutation } from "@apollo/client";
import { useModal } from "../ModalContext";
import { useHistory } from "react-router-dom";
import { SEND_SUBSCRIBE_PASSWORD } from '../../../graphql/graphql';

const ModalPassword = () => {
    let history = useHistory();
    const { modalValues, hide } = useModal();

    const {onChange, onSubmit, values} = useForm(passwordSendCallback, {
        login: '',
        password: ''
    });

    const [sendPassword] = useMutation(SEND_SUBSCRIBE_PASSWORD, {
        update(_, {data}) {
            hide();
            history.push('/subscribes')
        }
    });


    function passwordSendCallback() {
        console.log( {
            ...values,
            ...modalValues
        })

        sendPassword({
            variables: {
                ...values,
                ...modalValues
            }
        });
    }

    const randomGenerate = (event) => {
        event.preventDefault();

        const password = randomstring.generate(7);

        onChange({
            target: {
                name: 'password',
                value: password
            }
        });
    }

    return (
        <ModalWrapper
            title="Выдать пароль"
            desc="Для выдачи логина и пароля введите соответствующие данные:"
        >
            <form className={classes.Form} onSubmit={onSubmit}>
                <Group label="Логин">
                    <Input
                        name="login"
                        value={values.login}
                        placeholder="Введите логин"
                        onChange={onChange}
                    />
                </Group>
                <Group label="Пароль">
                    <Input
                        name="password"
                        value={values.password}
                        placeholder="Введите пароль"
                        onChange={onChange}
                    />
                    <Button type="random" icon={random} onClick={(event) => randomGenerate(event)}>
                        сгенерировать
                    </Button>
                </Group>
                <Group>
                    <Button size="normal">Выслать пароль клиенту</Button>
                </Group>
            </form>
        </ModalWrapper>
    )
}



export default ModalPassword;