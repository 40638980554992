import React from 'react';
import InputMask from 'react-input-mask';
import Label from '../Label/Label';
import classes from './Input.module.scss'
import pdf from '../../../assets/img/pdf.svg';

export const Input = ({ type = "text", placeholder = "Введите текст", name, value, onChange }) => {
    if (type === 'tel') {
        return (
            <InputMask mask="+7 (999) 999-99-99" value={value} placeholder={placeholder}
                      onChange={(event) => onChange(event)} autoComplete={`${name}-new`}>
                {(inputProps) =>
                    <input type={type} name={name} value={inputProps.value} className={classes.Item}
                           placeholder={inputProps.placeholder} id={inputProps.htmlFor} autoComplete={`${name}-new`} />
                }
            </InputMask>
        )
    } else {
        return (
            <input type={type} className={classes.Item} placeholder={placeholder} name={name} value={value} onChange={(event) => onChange(event) } autoComplete={`${name}-new`} />
        )
    }
}



export const InputFile = ({label, error, name, onChange, value}) => {
    const htmlFor = `name- ${Math.random()}`;
    const cls = [classes.Group];
    if (error) {
        cls.push(classes.Error)
    }

    return (
        <div className={cls.join(' ')}>
            <Label htmlForm={htmlFor} label={label}/>
            <label htmlFor={htmlFor} className={[classes.File, value && classes.FileChanged].join(' ')}>
                {!value ? 'Фото не выбранно...' : value}
                <span>
                    <img src={pdf} alt=""/>
                    Выберите файл
                </span>
            </label>
            <input type="file" name={name} id={htmlFor} onChange={(event) => onChange(event)}/>
            <span className={classes.Message}>{error}</span>
        </div>
    )
}