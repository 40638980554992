import React from 'react';
import classes from '../Modal.module.scss';
import Plan from "../../UI/Plan/Plan";
import Group from "../../UI/Group/Group";
import Button from "../../UI/Button/Button";
import Stations from "../../UI/Stations/Stations";
import DatePick from "../../UI/DatePick/DatePick";
import ModalWrapper from "../ModalWrapper";
import { useMutation} from '@apollo/client';
import { useModal } from "../ModalContext";
import { UPDATE_SUBSCRIBE } from "../../../graphql/graphql";
import Brand from "../../UI/Brand/Brand";



const ModalProlong = () => {
    const { hide, values, setErrors, errors, modalValues } = useModal();


    const [updateSubscribe, { loading }] = useMutation(UPDATE_SUBSCRIBE, {
        update(proxy, {data: { updateBsManagerSubscription: updateDevice }}) {
            hide();
            console.log(updateDevice);
        },
        onError(err) {
            setErrors({ ...errors, ...err.graphQLErrors[0].extensions.exception.errors});
        }
    });


    const onSubmit = (event) => {
        event.preventDefault();
        console.log({
            id: modalValues.id,
            station: values.station,
            plan: values.plan,
            date: values.date
        });
        updateSubscribe({
            variables: {
                id: modalValues.id,
                station: values.station,
                plan: values.plan,
                date: values.date
            }
        });
    }


    return (
        <ModalWrapper
            title="Управление подпиской"
            desc="Не забудьте сохранить данные:"
        >
            <form encType="multipart/form-data" className={loading ? `${classes.Form} ${classes.FormLoading}` : classes.Form} onSubmit={onSubmit}>
                <span className={classes.GeneralError}>{errors.general}</span>
                <Brand
                    name="device.brand"
                    error={errors.brand}
                    placeholder="Выберите марку из списка"
                    disabled={values.device.brand && true}
                />
                <Plan
                    name="plan"
                    label="Выберите тариф"
                    placeholder="Выберите тарифный план"
                    error={errors.plan}
                />

                <Stations
                    name="station.items"
                    placeholder="Выберите базу"
                    error={errors.station}
                />

                <DatePick/>

                <Group>
                    <Button className={classes.FormSubmit}>Сохранить</Button>
                </Group>
            </form>
        </ModalWrapper>
    )
};


export default ModalProlong;
