import React from 'react';
import Home from "./pages/Home/Home";
import User from "./pages/User/User";
import Users from "./pages/Users/Users";
import Login from "./pages/Login/Login";
import Stations from "./pages/Stations/Stations";
import Settings from "./pages/Settings/Settings";
import PageInvoiceSubscribes from "./pages/Subscribes/PageInvoiceSubscribes";
import PagePaidSubscribes from "./pages/Subscribes/PagePaidSubscribes";
import PageCurrentSubscribe from "./pages/Subscribes/PageCurrentSubscribe";
import PageActiveSubscribes from "./pages/Subscribes/PageActiveSubscribes";
import PageEndSubscribes from "./pages/Subscribes/PageEndSubscribes";
import PageComingSubscribes from "./pages/Subscribes/PageComingSubscribes";
import PageArchiveSubscribes from "./pages/Subscribes/PageArchiveSubscribes";
import Instructions from "./pages/Instructions/Instructions";
import PageCsv from "./pages/Csv/Csv";
import AuthRoute from "./hoc/AuthRouter";
import PrivateRoute from "./hoc/PrivateRouter";
import { SearchProvider } from "./context/search";

const Routes = () => {
    return (
        <SearchProvider>
            <AuthRoute path="/" exact component={Login} />
            <PrivateRoute path="/home" exact component={Home} />
            <PrivateRoute path="/users" exact component={Users} />
            <PrivateRoute path="/user/:id" component={User} />
            <PrivateRoute path="/invoice" component={PageInvoiceSubscribes} />
            <PrivateRoute path="/paid" component={PagePaidSubscribes} />
            <PrivateRoute path="/archive" component={PageArchiveSubscribes} />
            <PrivateRoute path="/subscribes" component={PageActiveSubscribes} />
            <PrivateRoute path="/subscribe/:id" component={PageCurrentSubscribe} />
            <PrivateRoute path="/coming" component={PageComingSubscribes} />
            <PrivateRoute path="/csv" component={PageCsv} />
            <PrivateRoute path="/end" component={PageEndSubscribes} />
            <PrivateRoute path="/instructions" component={Instructions} />
            <PrivateRoute path="/stations" component={Stations} />
            <PrivateRoute path="/settings" component={Settings} />
        </SearchProvider>
    )
}

export default Routes;