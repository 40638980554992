import React from 'react';
import Select from "react-select";
import Group from "../Group/Group";
import classes from "../Input/Input.module.scss";
import { useQuery } from "@apollo/client";
import { useModal } from "../../Modal/ModalContext";
import { FETCH_BRANDS } from "../../../graphql/graphql";


const Brand = ({ error, disabled, name, placeholder }) => {
    const { inputHandler, setBrand } = useModal();
    const { loading, data: { getBsBrands: brands } = {} } = useQuery(FETCH_BRANDS);

    return (
        <Group label="Марка приемника" error={error}>
            { brands &&
                <Select
                    isLoading={loading}
                    isDisabled={disabled && true}
                    className={error && classes.ErrorSelect}
                    classNamePrefix="select"
                    placeholder={placeholder}
                    options={brands && brands.map(item => {
                        return {
                            ...item,
                            name: name,
                            value: item.label
                        }
                    })}
                    onChange={(event) => {
                        inputHandler(event)
                        setBrand(event.type);
                    }}
                />
            }
        </Group>
    )
};

export default Brand;


