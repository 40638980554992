import React from 'react';
import Select from "react-select";
import Group from "../Group/Group";
import classes from "../Input/Input.module.scss";
import { useQuery } from "@apollo/client";
import { useModal } from "../../Modal/ModalContext";
import { FETCH_PLANS_OPTIONS } from "../../../graphql/graphql";


const Plan = ({ name, placeholder, label, error }) => {
    const { brandType, setPlan, planHandler } = useModal();
    const {loading, data: { getBsPlans: plans } = {}} = useQuery(FETCH_PLANS_OPTIONS, {
        variables: { type: brandType },
    });

    if (!brandType) return false;

    return (
        <>
            { plans && <Group label={label} error={null}>
                <Select
                    isLoading={loading}
                    isSearchable={false}
                    classNamePrefix="select"
                    maxMenuHeight={200}
                    placeholder={placeholder}
                    className={error && classes.ErrorSelect}
                    onChange={event => {
                        planHandler(event);
                        setPlan(event.count);
                        console.log(event);
                    }}
                    options={plans.map(item => {
                        return {
                            name: name,
                            id: item.id,
                            value: item.id,
                            type: item.value,
                            label: `${item.label} (${item.city.name}) (${item.time.name})`,
                            count: item.city.numberCount,
                            enumCount: item.city.enumCount
                        }
                    })}
                />
                <span className={classes.Message}>{error}</span>
            </Group> }
        </>
    )
};

export default Plan;

