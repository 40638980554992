import { gql } from "@apollo/client";

export const FETCH_STATIONS = gql`
    query Stations(  
        $page: Int
        $limit: Int
    ) {
        getBsStations(
            page: $page
            limit: $limit
        ) {
            data {
                id
                code
                city
                status
                satellite
                position {
                    lat
                    lon
                    alt
                }
                cords {
                    n
                    e
                }
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;


export const ARCHIVE_SUBSCRIBE = gql`
    mutation archiveBsSubscription(
        $id: String!
    ) {
        archiveBsSubscription(
            id: $id
        )
    }
`;


export const DELETE_SUBSCRIBE = gql`
    mutation deleteBsSubscription(
        $id: String!
    ) {
        deleteBsSubscription(
            id: $id
        )
    }
`;

export const DELETE_STATION = gql`
    mutation deleteBsStation(
        $stationId: String!
    ) {
        deleteBsStation(
            stationId: $stationId
        )
    }
`;



export const DELETE_INSTRUCTION = gql`
    mutation deleteBsInstruction(
        $id: String!
    ) {
        deleteBsInstruction(
            id: $id
        )
    }
`;

export const DELETE_USER = gql`
    mutation delete(
        $id: String!
    ) {
        delete(
            id: $id
        )
    }
`;


export const CREATE_INSTRUCTION = gql`
    mutation createBsInstruction(
        $label: String!
        $file: Upload!
    ) {
        createBsInstruction(instructionInput: {
            label: $label,
            file: $file
        }) {
            label
            file
        }
    }
`;


export const SEND_SUBSCRIBE_PASSWORD = gql`
    mutation activateBsSubscription(
        $id: String!,
        $login: String!
        $password: String!
    ) {
        activateBsSubscription(id: $id, login: $login, password: $password)
    }
`;


export const REGISTER_USER = gql`
    mutation register(
        $name: String!
        $lastname: String!
        $company: String!
        $city: String!
        $phone: String!
        $email: String!
        $sendPassword: Boolean!
    ) {
        register(
            registerInput: {
                name: $name
                lastname: $lastname
                company: $company
                city: $city
                phone: $phone
                email: $email
                sendPassword: $sendPassword
            }
        ) {
            id email name createdAt token
        }
    }
`;


export const MIGRATE_DEVICE = gql`
    mutation migrateBsSubscription(
        $deviceID: String!
        $userID: String!
    ) {
        migrateBsSubscription(
            deviceID: $deviceID
            userID: $userID
        )
    }
`;

export const CREATE_SUBSCRIBE = gql`
    mutation createBsManagerSubscription(
        $device: SubscriptionInputDevice!
        $station: SubscriptionInputStation!
        $pay: SubscriptionInputPay!
        $plan: SubscriptionInputPlan!
        $confirm: SubscriptionInputConfirm!
        $date: SubscriptionInputDate!
        $status: SubscriptionInputStatus!
        $userID: SubscriptionInputUser!
        $file: Upload!
    ) {
        createBsManagerSubscription(
            subscribeInput: {
                device: $device
                station: $station
                pay: $pay
                plan: $plan
                confirm: $confirm
                file: $file
                date: $date
                status: $status
                userID: $userID
            }
        ) {
            id
            device {
                id
                brand
                model
                serial
            }
            pay {
                method
                paid
            }
            date {
                createdAt
                paidAt
                disabledAt
            }
            plan {
                id
                label
                amount
            }
        }
    }
`;


export const CREATE_STATION = gql`
    mutation createBsStation(
        $code: String
        $city: String
        $satellite: String
        $position: StationInputPosition
        $cords: StationInputCords
    ) {
        createBsStation(
            stationInput: {
                code: $code
                city: $city
                satellite: $satellite
                position: $position
                cords: $cords
            }
        ) {
            id
        }
    }
`;


export const FETCH_BRANDS = gql`
    {
        getBsBrands {
            label
            value
            type
        }
    }
`;


export const FETCH_PLANS_OPTIONS = gql`
    query Plan ($type: String!) {
        getBsPlans(type: $type) {
            id
            label
            value
            price
            time {
                name
            }
            city {
                name
            }
            city {
                enumCount
                numberCount
            }
        }
    }
`;


export const FETCH_USERS = gql`
    {
        getUsers {
            id
            name
            login
        }
    }
`;

export const UPDATE_SUBSCRIBE = gql`
    mutation updateBsManagerSubscription(
        $id: ID!
        $plan: SubscriptionInputPlan!
        $station: SubscriptionInputStation!
        $date: SubscriptionInputDate!
    ) {
        updateBsManagerSubscription(
            subscribeUpdateInput: {
                id: $id
                plan: $plan
                station: $station
                date: $date
            }
        ) {
            id
            device {
                id
                brand
                model
                serial
            }
            pay {
                method
                paid
            }
            date {
                createdAt
                paidAt
                disabledAt
            }
            plan {
                id
                label
                amount
            }
        }
    }
`;

export const UPDATE_PAID_SUBSCRIBE = gql`
    mutation updateBsInvoice(
        $id: String!
        $status: String!
    ) {
        updateBsInvoice(
            id: $id
            status: $status
        )
    }
`;


export const FETCH_STATIONS_OPTIONS = gql`
    query StationsOptions(
        $page: Int
        $limit: Int
    ) {
        getBsStations(
            page: $page
            limit: $limit
        ) {
            data {
                id
                code
                city
            }
        }
    }
`;


export const FETCH_SUBSCRIBE_COUNT = gql`    
    {
        getBsSubscriptionsCounter {
            total
            endSubscribe
            waitingInvoice
            waitingPassword
            activeSubscribe
            comingSubscribe
            archiveSubscribe
        }
    }
`;


export const FETCH_INSTRUCTIONS = gql`
    {
        getBsInstructions {
            id
            label
            file
        }
    }
`;


export const LOGIN_USER = gql`
    mutation login(
        $login: String!,
        $password: String!
    ) {
        login(login: $login, password: $password) {
            id
            login
            name
            lastname
            createdAt
            token
        }
    }
`;

export const GET_ACTIVE_SUBSCRIPTIONS = gql`
    query SubscriptionsActive(
        $page: Int
        $limit: Int
        $search: String
    ) {
        getBsSubscriptions(
            filter: {
                paidStatus: "PAID",
                availableStatus: "ACTIVE",
                active: true,
                sort: {
                    field: "date.activatedAt",
                    param: -1
                }
            }, 
            page: $page,
            limit: $limit,
            search: $search
        ) {
            subscriptions {
                id
                device {
                    id
                    brand
                    model
                    serial
                }
                plan {
                    label
                    amount
                }
                date {
                    createdAt
                    disabledAt
                    activatedAt
                    paidAt
                }
                pay {
                    paid
                    method
                }
                station {
                    type
                    items {
                        label
                        code
                    }
                }
                available {
                    status
                }
                file
                archive
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;

export const GET_ARCHIVE_SUBSCRIPTIONS = gql`
    query SubscriptionsActive(
        $page: Int
        $limit: Int
    ) {
        getBsSubscriptions(
            filter: {
                archive: true,
                sort: {
                    field: "date.createdAt",
                    param: -1
                }
            },
            page: $page,
            limit: $limit
        ) {
            subscriptions {
                id
                device {
                    id
                    brand
                    model
                    serial
                }
                plan {
                    label
                    amount
                }
                date {
                    createdAt
                    disabledAt
                    activatedAt
                    paidAt
                }
                pay {
                    paid
                    method
                }
                station {
                    type
                    items {
                        label
                        code
                    }
                }
                available {
                    status
                }
                file
                archive
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;


export const GET_COMING_SUBSCRIPTIONS = gql`
    query SubscriptionsComing(
        $page: Int
        $limit: Int
    ) {
        getBsSubscriptions(
            filter: {
                paidStatus: "PAID",
                availableStatus: "ACTIVE",
                coming: true,
                sort: {
                    field: "date.activatedAt",
                    param: -1
                }
            },
            page: $page,
            limit: $limit
        ) {
            subscriptions {
                id
                device {
                    id
                    brand
                    model
                    serial
                }
                plan {
                    label
                    amount
                }
                date {
                    createdAt
                    disabledAt
                    activatedAt
                    paidAt
                }
                pay {
                    paid
                    method
                }
                station {
                    type
                    items {
                        label
                        code
                    }
                }
                available {
                    status
                }
                file
                archive
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;


export const GET_END_SUBSCRIPTIONS = gql`
    query SubscriptionsEnd(
        $page: Int
        $limit: Int
    ) {
        getBsSubscriptions(
            filter: {
                paidStatus: "PAID",
                availableStatus: "ACTIVE",
                end: true,
                sort: {
                    field: "date.activatedAt",
                    param: -1
                }
            }
            page: $page,
            limit: $limit
        ) {
            subscriptions {
                id
                device {
                    id
                    brand
                    model
                    serial
                }
                plan {
                    label
                    amount
                }
                date {
                    createdAt
                    disabledAt
                    activatedAt
                    paidAt
                }
                pay {
                    paid
                    method
                }
                station {
                    type
                    items {
                        label
                        code
                    }
                }
                available {
                    status
                }
                file
                archive
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;


export const GET_INVOICE_SUBSCRIPTIONS = gql`
    query SubscriptionsPaid(
        $page: Int
        $limit: Int
    ) {
        getBsSubscriptions(
            filter: {
                paidStatus: "NOT_PAID",
                paidMethod: "INVOICE",
                availableStatus: "NON_ACTIVE",
                sort: {
                    field: "date.createdAt",
                    param: -1
                }
            },
            page: $page,
            limit: $limit
        ) {
            subscriptions {
                id
                device {
                    id
                    brand
                    model
                    serial
                }
                plan {
                    label
                    amount
                }
                date {
                    createdAt
                    disabledAt
                    activatedAt
                    paidAt
                }
                pay {
                    paid
                    method
                    status
                }
                station {
                    type
                    items {
                        label
                        code
                    }
                }
                available {
                    status
                }
                file
                archive
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;


export const GET_PAID_SUBSCRIPTIONS = gql`
    query SubscriptionsPaid(
        $page: Int
        $limit: Int
    ) {
        getBsSubscriptions(
            filter: {
                paidStatus: "PAID",
                availableStatus: "NON_ACTIVE",
                sort: {
                    field: "date.paidAt",
                    param: -1
                }
            },
            page: $page,
            limit: $limit
        ) {
            subscriptions {
                id
                device {
                    id
                    brand
                    model
                    serial
                }
                plan {
                    label
                    amount
                }
                date {
                    createdAt
                    disabledAt
                    activatedAt
                    paidAt
                }
                pay {
                    paid
                    method
                }
                station {
                    type
                    items {
                        label
                        code
                    }
                }
                available {
                    status
                }
                file
                archive
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`;


export const GET_USER = gql`
    query User ($id: String!) {
        getUserById(id: $id) {
            id
            name
            login
            lastname
            company
            phone
            city
            email
            subscriptions {
                id
                device {
                    id
                    brand
                    model
                    serial
                }
                plan {
                    label
                    amount
                }
                date {
                    createdAt
                    disabledAt
                    activatedAt
                    paidAt
                }
                pay {
                    paid
                    method
                    status
                }
                station {
                    type
                    items {
                        label
                        code
                    }
                }
                available {
                    status
                }
                file
            }
        }
    }
`;


export const GET_USER_SUBSCRIBE = gql`
    query Subscribe ($id: String!) {
        getBsSubscription(id: $id) {
            id
            device {
                id
                brand
                model
                serial
            }
            plan {
                label
                amount
            }
            date {
                createdAt
                disabledAt
                activatedAt
                paidAt
            }
            pay {
                paid
                method
            }
            station {
                type
                items {
                    label
                    code
                }
            }
            available {
                status
                login
                password
            }
            user
            file
        }
        getBsInvoice(deviceID: $id) {
            id
            bank
            bin
            inv
            address
        }
    }
`;


export const GET_SUBSCRIBE_INVOICE = gql`
    query Invoice ($deviceID: String!) {
        getBsInvoice(deviceID: $deviceID) {
            id
            bank
            bin
            inv
        }
    }
`;


export const GET_USERS = gql`    
    query Users (
        $page: Int
        $limit: Int
        $search: String
    ) {
        getUsersPage(
            page: $page,
            limit: $limit,
            search: $search
        ) {
            data {
                id
                name
                login
                lastname
                company
                phone
                city
                email
                subscriptions {
                    id
                    device {
                        id
                        brand
                        model
                        serial
                    }
                }
            }
            paginator {
                itemCount
                perPage
                pageCount
                currentPage
                slNo
                hasPrevPage
                hasNextPage
                prev
                next
            }
        }
    }
`



export const GET_CSV = gql`
    mutation createCsvSubscribes(
        $startDate: String!
        $endDate: String!
        $payMethod: String!
    ) {
        createCsvSubscribes(
            csvInput: {
                startDate: $startDate
                endDate: $endDate
                payMethod: $payMethod
    
            }
        )
    }
`;


